import React from "react";
import { RecoilRoot } from "recoil";
import SiteHead from "../../head";

import { SessionPage as SessionPageComponent } from "../../sessionPage/sessionPage";
import { sessionData } from "../../sessionsPage/sessions";

const SessionPage = () => {
  return (
    <RecoilRoot>
      <SessionPageComponent session={sessionData[6]} />
    </RecoilRoot>
  );
};

export const Head = () => (
  <SiteHead meta={{ title: `${sessionData[6].title} | EXCELCON` }} />
);

export default SessionPage;
